import React from 'react';
import { Link } from '../../../redesign';
import Melissa from '../../../assets/melissa_wood_health.svg';

import MisfitsMarket from '../../../assets/misfits_market.svg';
import DailyHarvest from '../../../assets/daily_harvest.svg';
import Mira from '../../../assets/mira.svg';

const perks = [
  {
    text: (
      <>
        <span className="font-semibold">15% discount</span> on
        professional-grade supplements at the{' '}
        <Link
          destination="https://store.parsleyhealth.com/"
          className="text-ph-sage underline"
        >
          Parsley Health Store
        </Link>
        . Shop and save on bestselling supplements including Vitamin D3 + K3,
        magnesium, protein powder, multivitamins, and probiotics.
      </>
    )
  },
  {
    text:
      "Exclusive savings from 100+ best-in-class health & wellness partners like Athletic Greens and The Sculpt Society. For almost ten years, we've hand-selected and worked with top brands to help you succeed."
  },
  {
    text:
      'Invite-only events and offerings for local members at our NYC and LA locations.'
  }
];

const logos = [
  {
    src: <Melissa />,
    alt: 'Melissa Wood Health'
  },
  {
    src: <MisfitsMarket />,
    alt: 'Misfits Market'
  },
  {
    src:
      'https://cdn.sanity.io/images/mur2ep80/production/0745c5cef9741fe6af28bf800611c1c3b4eaa432-500x139.webp',
    alt: 'Open'
  },
  {
    src:
      ' https://cdn.sanity.io/images/mur2ep80/production/462cf283a195ed90c65fba46f0a9db9d13eca4b7-800x67.webp',
    alt: 'Athletic Greens'
  },
  {
    src:
      'https://cdn.sanity.io/images/mur2ep80/production/bf69c4868b1083e7062a844b36ccab2215044fb3-800x249.webp',
    alt: 'Armra'
  },
  {
    src:
      'https://cdn.sanity.io/images/mur2ep80/production/fd3ff6381cab1f282b145ab1b3487e8f649ecedd-796x800.webp',
    alt: "Beekeeper's Naturals"
  },
  {
    src: <Mira />,
    alt: 'Mira'
  },
  {
    src:
      'https://cdn.sanity.io/images/mur2ep80/production/35531d3fbc1f48de8550dcc8c87ab97cb8933249-1102x126.webp',
    alt: 'The Class'
  },
  {
    src: <DailyHarvest />,
    alt: 'Daily Harvest'
  },
  {
    src:
      'https://cdn.sanity.io/images/mur2ep80/production/e256a4bb465b6cad40fd6cf60a664a20b84fee6e-503x61.webp',
    alt: 'Sakara Life'
  }
];

const PerksSection = () => {
  return (
    <section className="text-teodor rounded-b-[64px] bg-ph-gradient-peachy md:rounded-b-[96px]">
      <div className="container mx-auto max-w-6xl px-6 py-16 md:px-16 md:py-24">
        <h2 className="text-ph-h1 mx-auto mb-6 max-w-2xl text-center md:mb-8">
          Plus, get $1,500 in value with exclusive member perks
        </h2>
        <p className="text-ph-subtitle mx-auto mb-10 max-w-2xl text-center md:mb-16">
          We&apos;ve partnered with the best health products, brands, and
          solutions to help your journey. We even offer our own brand of
          professional-grade supplements to ensure quality—and offer those at a
          discount to our members, too.
        </p>

        <div className="grid gap-8 md:grid-cols-2">
          <div className="order-2 space-y-4 md:order-1">
            <h3 className="text-ph-h5">All our programs include:</h3>
            {perks.map((perk, index) => (
              <div key={index} className="flex gap-4">
                <div className="mt-1 flex-shrink-0">
                  <svg
                    className="h-5 w-5 text-ph-sage"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <p className="text-ph-p1-colorless text-ph-forest">
                  {perk.text}
                </p>
              </div>
            ))}
          </div>

          <div className="text-teodor order-1 grid h-full grid-cols-2 gap-4 md:order-2">
            {logos.map((logo, index) => (
              <div
                key={index}
                className="flex items-center justify-center rounded-lg text-ph-forest h-1/2 w-1/2 object-contain text-ph-forest"
                style={
                  logo.alt === 'Daily Harvest'
                    ? {
                        width: '190%',
                        marginLeft: '-56%'
                      }
                    : {}
                }
              >
                {React.isValidElement(logo.src) ? (
                  logo.src
                ) : (
                  <img src={logo.src as string} alt={logo.alt} />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PerksSection;
